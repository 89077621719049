import { Component, useState, useEffect } from 'react';
import './Calc.css';
import Spinner from '../Spinner/Spinner';
import { useLocation, useNavigate } from "react-router-dom"

function Calculator() {

  const axios = require('axios');


  const [kk, setkk] = useState('');
  const [kk2, setkk2] = useState('');
  const [kk3, setkk3] = useState('');
  const [kk4, setkk4] = useState('');
  const [mainPage, setMainPage] = useState('');
  const [mainPageURL, setMainPageURL] = useState([]);
  const [altPage, setAltPage] = useState('');
  const [pageVisits, setPageVisits] = useState([]);
  const [pagePerVisits, setPagePerVisits] = useState([]);
  const [pageWeight, setPageWeight] = useState([]);
  const [cdn, setCdn] = useState([]);
  const [altPageWeight, setAltPageWeight] = useState(0);
  const [emission, setEmission] = useState(0);
  const [dataEmission, setDataEmission] = useState(0);
  const [endDeviceEmission, setEndDeviceEmission] = useState(0);
  const [totalEmissionIntensityLocal, setTotalEmissionIntensityLocal] = useState(0);
  const [totalEmissionLocalperyear, setTotalEmissionLocalperyear] = useState(0);
  const [totalEmissionLocalLifecycleperyear, setTotalEmissionLocalLifecycleperyear] = useState(0);
  const [totalEmissionIntensityPerpageViewGlobal, setTotalEmissionIntensityPerpageViewGlobal] = useState(0);
  const [spinLoading, setSpinLoading] = useState(false);
  const [drop1Value, setDrop1Value] = useState("y");
  const [drop2Value, setDrop2Value] = useState("y");
  const [drop3Value, setDrop3Value] = useState("US");
  const [drop4Value, setDrop4Value] = useState("US");
  const [calculate, setCalculate] = useState(0);
  const [pageSplit, setPageSplit] = useState([]);
  const [allTableData, setAllTableData] = useState("y");
  const [errorMsg, setErrorMsg] = useState(0);
  const [domainName, setDomainName] = useState([]);
  const [errorVal, setErrorVal] = useState("");
  const [wwVisits, setwwVisits] = useState([]);

  const [emissionDiffString, setEmissionDiffString] = useState("");
  const [emissionDiff, setEmissionDiff] = useState(0);

  const [emissionsperMvisit, setEmissionsperMvisit] = useState(0);
  const [totalEmissionIntensityLocalPageview, setTotalEmissionIntensityLocalPageview] = useState(0);

  const [eqiFlight, setEqiFlight] = useState(0);
  const [eqiTree, setEqiTree] = useState(0);

  const [navLogin, setNavLogin] = useState(0);

  const loc = useLocation();

  let authVal = false;

  if (loc != null && loc.state != null && loc.state.authorized === true) {
    authVal = true;

  }

  // async function pagespeed(url, mpage) {
  //   const resp = axios.get('https://www.googleapis.com/pagespeedonline/v5/runPagespee?url=' + mPage + '&key=' + loc.state.k.k1)
  //   .then(response => {
  //     let k = response.data.lighthouseResult.audits["network-requests"].details.items;
  //     let totalTransferSize = 0;
  //     for (let i = 0; i < k.length; i++) {
  //       totalTransferSize = totalTransferSize + k[i].transferSize;
  //     }

  //     setPageWeight(totalTransferSize / 1000);
  //     console.log(response.data.lighthouseResult.audits["network-requests"].details.items[0]);
  //     setSpinLoading(false);
  //   })
  //   .catch(error => {
  //     console.log(error);
  //       setSpinLoading(false);
  //       setErrorMsg("Pagespeed API Error");
  //   });

  //   return pw;
  // }

  // async function pagespeedCalls(url, mpage) {
  //   let pwArr = [];
  //   let pwOut = [];
  //   for (let i = 0; i <2; i ++) {


  //     pwOut = await pagespeed(craw[i]);
  //     for (let i = 0; i < pwOut.length; i++) {
  //       if (pwOut[i] > 0) {
  //         pwArr.push(pwOut[i]);
  //       }

  //     }

  //     console.log("pwArr", pwArr);
  //   }

  //   let sumpwArr = pwArr[0] * 0.1;
  //   let len = pwArr.length;
  //   for (let i = 1; i < len; i++) {
  //     sumpwArr = sumpwArr + pwArr[i] * (0.9 / (len - 1));
  //   }
  //   setPageWeight(sumpwArr);
  //   setSpinLoading(false);
  //   // if (!e) {
  //   let n = calculate + 1;
  //   setCalculate(n);
  //   // }
  //   if (pwArr.length == 0)
  //     setErrorMsg("Pagespeed API crawling failed");
  //   return pwArr;
  // }


  async function handleSubmit(e) {
    if (e)
      e.preventDefault();


    setPageWeight([]);
    setPageVisits([]);
    setPagePerVisits([]);
    setTotalEmissionIntensityLocal("");
    setTotalEmissionLocalperyear("");
    setTotalEmissionLocalLifecycleperyear("");
    setTotalEmissionIntensityPerpageViewGlobal("");
    setEmission("");
    setEmissionsperMvisit("");
    setTotalEmissionIntensityLocalPageview("");
    setSpinLoading(true);
    setErrorMsg(0);
    setEmissionDiff(0);
    setEmissionDiffString("");
    setEqiFlight(0);
    setEqiTree(0);
    setErrorVal("");

    setCdn([]);


    await axios.post('https://footsprint.uc.r.appspot.com/getAllTableData?key=' + loc.state.k.k4)
      .then(response => {
        setAllTableData(response.data);
      })
      .catch(error => {
        console.log(error);
      });


    let mp = mainPage;
    // mp = mainPage.toLowerCase();
    let mpURL = mp.split(",");
    console.log("urls", mpURL);
    setMainPageURL(mpURL);

    for (let i = 0; i < mpURL.length; i++) {
      let url_val = mpURL[i];
      console.log("url ", i, url_val);
      let random = await apiValues(url_val);
    }
    setSpinLoading(false);

    let n = 0;
    n = calculate + 1;
    setCalculate(n);
  }

  async function apiValues(mainURL) {

    let currentDate = new Date();

    let curryear = currentDate.getFullYear();
    let currMonth = currentDate.getMonth();
    if (currMonth == 0) {
      curryear = curryear - 1;
      currMonth = 11;
    }
    else if (currMonth == 1) {
      curryear = curryear - 1;
      currMonth = 12;
    }
    else {
      currMonth = currMonth - 1;
    }

    let startDate = '';
    startDate = (curryear - 1) + "-" + currMonth;
    let endDate = '';
    endDate = curryear + "-" + currMonth;
    console.log(startDate);
    console.log(endDate);

    let mPage = mainURL;

    if (mainURL.includes("https://www.") || mainURL.includes("https://") || mainURL.includes("http://")) {
      mPage = mainURL;
    }
    else if (mainURL.startsWith("www.")) {
      mPage = "https://" + mainURL;
    }
    else {
      mPage = "https://www." + mainURL;
    }

    console.log("mpage", mPage);


    let domain = (new URL(mPage));
    domain = domain.hostname.replace('www.', '');
    let dName = domainName;
    dName.push(domain);
    setDomainName(dName);



    await axios.get('https://api.similarweb.com/v1/website/' + domain + '/total-traffic-and-engagement/visits?api_key=' + loc.state.k.k2 + '&start_date=' + startDate + '&end_date=' + endDate + '&country=' + drop3Value + '&granularity=monthly&main_domain_only=false&format=json&show_verified=false&mtd=false')
      .then(response => {
        let pv = '';
        pv = response.data.visits;
        let totalVisits = 0;
        for (let i = 1; i < pv.length; i++) {
          totalVisits = totalVisits + pv[i].visits;
        }
        // if (totalVisits < 1000) {
        //   setErrorMsg("too small amount of sessions data to have significant results");
        // }
        totalVisits = totalVisits / (pv.length - 1);
        let pVArr = [];
        pVArr = pageVisits;
        pVArr.push(totalVisits.toFixed(2));
        setPageVisits(pVArr);
      })
      .catch(error => {
        console.log(error);
        //setErrorMsg("Similar web API failed");
        let pVArr = [];
        pVArr = pageVisits;
        pVArr.push("err");
        setPageVisits(pVArr);
      });


    await axios.get('https://api.similarweb.com/v1/website/' + domain + '/total-traffic-and-engagement/pages-per-visit?api_key=' + loc.state.k.k2 + '&start_date=' + startDate + '&end_date=' + endDate + '&country=' + drop3Value + '&granularity=monthly&main_domain_only=false&format=json&show_verified=false&mtd=false')
      .then(response => {

        let ppv = response.data.pages_per_visit;
        let totalPVisits = 0;
        for (let i = 1; i < ppv.length; i++) {
          totalPVisits = totalPVisits + ppv[i].pages_per_visit;
        }
        totalPVisits = totalPVisits / (ppv.length - 1);
        let ppVArr = pagePerVisits;
        ppVArr.push(totalPVisits.toFixed(2));
        setPagePerVisits(ppVArr);
        console.log(response.data.pages_per_visit);
      })
      .catch(error => {
        console.log(error);
        //setErrorMsg("Similar web API failed");
        let ppVArr = pagePerVisits;
        ppVArr.push("err");
        setPagePerVisits(ppVArr);
      });

    await axios.get('https://api.similarweb.com/v1/website/' + domain + '/total-traffic-and-engagement/visits-split?api_key=' + loc.state.k.k2 + '&start_date=' + startDate + '&end_date=' + endDate + '&country=' + drop3Value + '&main_domain_only=false&format=json&show_verified=false')
      .then(response => {

        let k = {
          desktopVal: response.data.desktop_visit_share,
          mobileVal: response.data.mobile_web_visit_share
        };

        let pgSplit = pageSplit;
        pgSplit.push(k);

        setPageSplit(pgSplit);
        console.log("split", k);
      })
      .catch(error => {
        console.log(error);
        let pgSplit = pageSplit;
        pgSplit.push("err");

        setPageSplit(pgSplit);
      });


    axios.get('https://api.similarweb.com/v4/website/' + domain + '/geo/traffic-by-country?api_key=' + loc.state.k.k2 + '&start_date=' + startDate + '&end_date=' + endDate)
      .then(response => {

        let tDeksArr = wwVisits;
        let totalwwDesktop = 0;

        let arr = response.data.records;

        console.log("WW ARR", arr);

        for (let i = 0; i < arr.length; i++) {

          totalwwDesktop = totalwwDesktop + arr[i].visits;

          //console.log("totalww", totalwwDesktop);

          if (drop3Value == 'US' && arr[i].country_name == "United States") {
            totalwwDesktop = totalwwDesktop - arr[i].visits;
            // console.log("Us value removed from ww total", totalwwDesktop - arr[i].visits);
          }
          if (drop3Value == 'CA' && arr[i].country_name == "Canada") {
            totalwwDesktop = totalwwDesktop - arr[i].visits;
            // console.log("CA value removed from ww total", totalwwDesktop - arr[i].visits);
          }
          if (drop3Value == 'FR' && arr[i].country_name == "France") {
            totalwwDesktop = totalwwDesktop - arr[i].visits;
            // console.log("FR value removed from ww total", totalwwDesktop - arr[i].visits);
          }
        }

        totalwwDesktop = totalwwDesktop / 12;
        tDeksArr.push(totalwwDesktop);
        setwwVisits(tDeksArr);
        //console.log("totalwwDesktop", totalwwDesktop);
      })
      .catch(error => {
        console.log(error);
        let tDeksArr = wwVisits;
        tDeksArr.push("err");
        setwwVisits(tDeksArr);
      });


    await axios.get('https://www.who-hosts-this.com/API/Host?key=' + loc.state.k.k3 + '&url=' + domain)
      .then(response => {

        let cdnArr = cdn;
        if (response.data && response.data.results && response.data.results[0].isp_name) {
          cdnArr.push(response.data.results[0].isp_name);
        }
        else {
          cdnArr.push("other");
        }
        //let cdnValue = response.data.results[0].isp_name;
        setCdn(cdnArr);
        console.log(cdnArr);
      })
      .catch(error => {
        console.log(error);
        let cdnArr = cdn;
        cdnArr.push("err");
        setCdn(cdnArr);
      });

    let cnt = 0;
    while (cnt <= 3) {

      await axios.get('https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=' + mPage + '&key=' + loc.state.k.k1)
        .then(response => {
          let k = response.data.lighthouseResult.audits["network-requests"].details.items;
          let totalTransferSize = 0;
          for (let i = 0; i < k.length; i++) {
            totalTransferSize = totalTransferSize + k[i].transferSize;
          }

          let tTransfer = totalTransferSize / 1000;
          let page_weightArr = pageWeight;
          page_weightArr.push(tTransfer);

          setPageWeight(page_weightArr);
          console.log("pw inside call", pageWeight);
          //setSpinLoading(false);

          //let n = calculate + 1;
          //setCalculate(n);
          cnt = 4;

        })
        .catch(error => {
          console.log(error);
          cnt++;

          if (cnt == 3) {
            //setSpinLoading(false);
            //setErrorMsg("Pagespeed API Error");
            let page_weightArr = pageWeight;
            page_weightArr.push("err");

            setPageWeight(page_weightArr);
          }
        });
    }

  }

  const handleDrop1 = (e) => {
    e.preventDefault();
    setDrop1Value(e.target.value);

  }

  const handleDrop2 = (e) => {
    e.preventDefault();
    setDrop2Value(e.target.value);
  }


  const handleDrop3 = (e) => {
    e.preventDefault();
    setDrop3Value(e.target.value);

  }

  const handleDrop4 = (e) => {
    e.preventDefault();
    setDrop4Value(e.target.value);
  }


  useEffect(() => {

    async function cal() {

      if (calculate > 0) {

        console.log('pw', pageWeight);
        console.log('pv', pageVisits);
        console.log('pageper v', pagePerVisits);
        let totalEmiArr = "", totalEmiVisitArr = "", emiperMVisitArr = "", eqiTreeArr = "", eqiFlightArr = "", errValArr = "";

        for (let itr = 0; itr < mainPageURL.length; itr++) {
          if (pageWeight[itr] != "err" && pageVisits[itr] != "err" && pagePerVisits[itr] != "err" && pageSplit[itr] != "err" && cdn[itr] != "err" && wwVisits[itr] != "err") {

            // let pageWeightSplited = pageWeight * pageSplit.desktopVal + pageWeight * pageSplit.mobileVal * pwReduction;
            // console.log('pw-split', pageWeightSplited);
            //pageWeightSplited contains pageweight calculated using the split between 

            let totalPagevisits = pageVisits[itr] * pagePerVisits[itr];

            console.log("page pervisit", pagePerVisits[itr]);
            console.log("total pages visits (local)", totalPagevisits);

            let totalVisitsww = (wwVisits[itr] / pageSplit[itr].desktopVal);

            console.log("TOTAL VISIT WW (excluding local)", totalVisitsww);

            let totalpageV = (totalVisitsww * pagePerVisits[itr]) + totalPagevisits;

            let totalPageVisitsww = totalVisitsww * pagePerVisits[itr];

            console.log("TOTAL PAGE VISIT WW (excluding local)", totalPageVisitsww);

            let totalVisits = totalVisitsww + parseFloat(pageVisits[itr]);

            console.log("TOTAL VISITS WW + local", totalVisits);

            let usefulData = allTableData[5];
            let noScrollToScroll = 1.25;
            let lazyLoadingScroll = 0.5;
            let cacheReduction = 0.95;
            let returningUsers = 0.3
            let newUsers = 0.7;
            let pwReduction = 0.11;

            // for (let i = 0; i < usefulData.length; i++) {
            //   if (usefulData[i].variable == "No scroll to scroll") {
            //     noScrollToScroll = usefulData[i].value;
            //   }
            //   if (usefulData[i].variable == "Lazy loading % scroll down normal website") {
            //     lazyLoadingScroll = usefulData[i].value;
            //   }
            //   if (usefulData[i].variable == "Cache reduction") {
            //     cacheReduction = usefulData[i].value;
            //   }
            //   if (usefulData[i].variable == "New users") {
            //     newUsers = usefulData[i].value;
            //   }
            //   if (usefulData[i].variable == "Returning users") {
            //     returningUsers = usefulData[i].value;
            //   }
            //   if (usefulData[i].variable == "Page weight reduction smartphones") {
            //     pwReduction = usefulData[i].value;
            //   }

            // }
            let pageWeightScroll = pageWeight[itr] * noScrollToScroll;


            //Bleow if will never run as default value of lazy loading = 'y'
            if (drop1Value === "n") {
              pageWeightScroll = pageWeight[itr];

            }

            console.log("pw scroll", pageWeightScroll);

            console.log("data", allTableData[1]);
            console.log("data2", allTableData[2]);
            console.log("data3", allTableData[3]);
            console.log("data4", allTableData[4]);
            console.log("data5", allTableData[5]);
            console.log("data6", allTableData[6]);

            // allTableDate contains data for server, transmission and end user grid intensity. 
            //Will be able to see this data as data, data2 and data3 on console

            let homePageAvgNoCached = pageWeight[itr] * (1 - lazyLoadingScroll) + pageWeightScroll * lazyLoadingScroll;

            let homePageWtCached = pageWeight[itr] * (1 - cacheReduction);

            let homePageWtScrollCached = pageWeightScroll * (1 - cacheReduction);

            let homePageAvgCached = homePageWtCached * (1 - lazyLoadingScroll) + homePageWtScrollCached * (1 - lazyLoadingScroll);

            if (drop2Value === "n") {
              homePageAvgNoCached = homePageAvgCached;
            }

            console.log("homePage Avg NoCached", homePageAvgNoCached);
            console.log("homePage Wt Cached", homePageWtCached);
            console.log("homePage Wt Scroll Cached", homePageWtScrollCached);
            console.log("home page Avg Cached (scroll+normal)", homePageAvgCached);


            //alternative calculation below
            let alternativehomePageAvgNoCached = pageWeight[itr] * (1 - lazyLoadingScroll) + pageWeightScroll * lazyLoadingScroll;

            let alternativehomePageWtCached = pageWeight[itr] * (1 - cacheReduction);

            let alternativehomePageWtScrollCached = pageWeightScroll * (1 - cacheReduction);

            let alternativehomePageAvgCached = alternativehomePageWtCached * (1 - lazyLoadingScroll) + alternativehomePageWtScrollCached * lazyLoadingScroll;

            if (drop2Value === "n") {
              alternativehomePageAvgNoCached = alternativehomePageAvgCached;
            }

            console.log("alternative Page Avg NoCached", alternativehomePageAvgNoCached);
            console.log("alternative PageWt Cached", alternativehomePageWtCached);
            console.log("alternative PageWt Scroll Cached", alternativehomePageWtScrollCached);
            console.log("alternative page Avg Cached (scroll+normal)", alternativehomePageAvgCached);

            let isp = cdn[itr].toLowerCase();
            console.log("isp from API", isp);

            let ispList = allTableData[4];

            let serveLocation = "other";
            let ff = 0;

            let cdnSel = "other";
            for (let i = 0; i < ispList.length; i++) {
              if (ispList[i].cdn == "microsoft azure") {
                if ((isp.includes("azure") || isp.includes("microsoft")) && ispList[i].country == drop3Value.toLowerCase()) {
                  serveLocation = drop3Value;
                  console.log("cdn through API=", isp, " ; cdn selected=", ispList[i].cdn);
                  cdnSel = ispList[i].cdn.toLowerCase();
                  ff++;
                  break;
                }
              }
              else {
                if (isp.includes(ispList[i].cdn) && ispList[i].country == drop3Value.toLowerCase()) {
                  serveLocation = drop3Value;
                  console.log("cdn through API=", isp, " ; cdn selected=", ispList[i].cdn);
                  cdnSel = ispList[i].cdn.toLowerCase();
                  ff++;
                  break;
                }
              }
            }

            console.log("final server location", serveLocation);

            let serverGridTable = allTableData[1];

            let serverGridIntensity = 709; //constant intialization, value set below

            if (serveLocation == 'US') {
              for (let i = 0; i < serverGridTable.length; i++) {
                if (serverGridTable[i].census_division_state_province == "us total") {
                  serverGridIntensity = serverGridTable[i].grid_intensity_grCO2_kWh;
                }
              }
            }

            if (serveLocation == 'CA') {
              for (let i = 0; i < serverGridTable.length; i++) {
                if (serverGridTable[i].census_division_state_province == "canada average") {
                  serverGridIntensity = serverGridTable[i].grid_intensity_grCO2_kWh;
                }
              }
            }
            if (serveLocation == 'FR') {
              for (let i = 0; i < serverGridTable.length; i++) {
                if (serverGridTable[i].country == "fr") {
                  serverGridIntensity = serverGridTable[i].grid_intensity_grCO2_kWh;
                }
              }
            }
            if (serveLocation == 'other') {
              for (let i = 0; i < serverGridTable.length; i++) {
                if (serverGridTable[i].country == "ww") {
                  serverGridIntensity = serverGridTable[i].grid_intensity_grCO2_kWh;
                }
              }
            }

            let sGww = 867;
            for (let i = 0; i < serverGridTable.length; i++) {
              if (serverGridTable[i].country == "ww") {
                sGww = serverGridTable[i].grid_intensity_grCO2_kWh;
              }
            }

            console.log("SERVER GRID WW", sGww);

            console.log("SERVER GRID WW local", serverGridIntensity);

            serverGridIntensity = serverGridIntensity * (totalPagevisits / (totalPageVisitsww + totalPagevisits)) + sGww * (1 - (totalPagevisits / (totalPageVisitsww + totalPagevisits)));

            console.log("SERVER GRID TOTAL", serverGridIntensity);

            let conversionData = allTableData[0];
            let energyIntensity = allTableData[6];

            let pageEmissionTable = allTableData[7];

            let kbToMb = 0.001;
            let mbToGb = 0.001;
            let kgToT = 0.001;

            // for (let i = 0; i < conversionData.length; i++) {
            //   if (conversionData[i].variable == "kB to MB") {
            //     kbToMb = conversionData[i].value;
            //   }
            //   if (conversionData[i].variable == "MB to GB") {
            //     kbToMb = conversionData[i].value;
            //   }
            //   if (conversionData[i].variable == "Kg to T") {
            //     kgToT = conversionData[i].value;
            //   }
            // }

            let DteiDataCenterNormal = 0.05;
            let DteiNetworkWiFi = 0.03;
            let DteiNetworkSmartphonesAvg = 0.07;
            let DteiComputer = 0.12;
            let DteiMobile = 0.24;

            // for (let i = 0; i < energyIntensity.length; i++) {
            //   if (energyIntensity[i].variable == "Dtei Data Center Normal") {
            //     DteiDataCenterNormal = energyIntensity[i].value;
            //   }
            //   if (energyIntensity[i].variable == "Dtei Network Wi-Fi") {
            //     DteiNetworkWiFi = energyIntensity[i].value;
            //   }
            //   if (energyIntensity[i].variable == "Dtei Network Smartphones global average") {
            //     DteiNetworkSmartphonesAvg = energyIntensity[i].value;
            //   }
            //   if (energyIntensity[i].variable == "computer") {
            //     DteiComputer = energyIntensity[i].value;
            //   }
            //   if (energyIntensity[i].variable == "mobile") {
            //     DteiMobile = energyIntensity[i].value;
            //   }
            // }

            let cdnVal = 0;
            if (cdnSel.includes("akamai")) {
              cdnVal = 0.2;
            }
            console.log("ServerEmiHomeAvgCached values", homePageAvgCached, DteiDataCenterNormal, kbToMb, mbToGb, serverGridIntensity, cdnVal);
            let ServerEmiHomeAvgCached = homePageAvgCached * DteiDataCenterNormal * kbToMb * mbToGb * serverGridIntensity * (1 - cdnVal);  //Dtei Data Center Normal = 0.05, conversion from KB to GB
            console.log("ServerEmiHomeAvgNoCached values", homePageAvgNoCached, DteiDataCenterNormal, kbToMb, mbToGb, serverGridIntensity, cdnVal);
            let ServerEmiHomeAvgNoCached = homePageAvgNoCached * DteiDataCenterNormal * kbToMb * mbToGb * serverGridIntensity * (1 - cdnVal); //Dtei Data Center Normal = 0.05, conversion from KB to GB

            let alternativeServerEmiHomeAvgCached = alternativehomePageAvgCached * DteiDataCenterNormal * kbToMb * mbToGb * serverGridIntensity * (1 - cdnVal);  //Dtei Data Center Normal = 0.05, conversion from KB to GB

            let alternativeServerEmiHomeAvgNoCached = alternativehomePageAvgNoCached * DteiDataCenterNormal * kbToMb * mbToGb * serverGridIntensity * (1 - cdnVal); //Dtei Data Center Normal = 0.05, conversion from KB to GB

            console.log("Server Emi Home Avg Cached", ServerEmiHomeAvgCached);
            console.log("Server Emi Home Avg NoCached", ServerEmiHomeAvgNoCached);
            console.log("alternative Server Emi Avg Cached", alternativeServerEmiHomeAvgCached);
            console.log("alternative Server Emi Avg NoCached", alternativeServerEmiHomeAvgNoCached);


            //pageperVisit = totalPageview/totalvisits
            let serverEmiAvg = ((ServerEmiHomeAvgNoCached * newUsers + ServerEmiHomeAvgCached * returningUsers) * (1 / (totalpageV / totalVisits))) + ((alternativeServerEmiHomeAvgNoCached * newUsers + alternativeServerEmiHomeAvgCached * returningUsers) * (1 - (1 / (totalpageV / totalVisits))));

            //below if condition will not be executed as 'Use all cache' option selector has been removed from front end and value has been hardcoded = 'y'
            // if (drop2Value === "n") {
            //   serverEmiAvg = ServerEmiHomeAvgNoCached + alternativeServerEmiHomeAvgNoCached; //no lazy loading value ; pageperVisit = totalPageview/totalvisits
            // }

            console.log("server Emission Avg Dekstop", serverEmiAvg);


            let ServerEmiHomeAvgCachedMob = homePageAvgCached * (1 - pwReduction) * DteiDataCenterNormal * kbToMb * mbToGb * serverGridIntensity * (1 - cdnVal); //Page weight reduction smartphones = (1-11%), Dtei Data Center Normal = 0.05, conversion from KB to GB

            let ServerEmiHomeAvgNoCachedMob = homePageAvgNoCached * (1 - pwReduction) * DteiDataCenterNormal * kbToMb * mbToGb * serverGridIntensity * (1 - cdnVal);//Page weight reduction smartphones = (1-11%), Dtei Data Center Normal = 0.05, conversion from KB to GB

            let alternativeServerEmiHomeAvgCachedMob = alternativehomePageAvgCached * (1 - pwReduction) * DteiDataCenterNormal * kbToMb * mbToGb * serverGridIntensity * (1 - cdnVal); //Page weight reduction smartphones = (1-11%), Dtei Data Center Normal = 0.05, conversion from KB to GB

            let alternativeServerEmiHomeAvgNoCachedMob = alternativehomePageAvgNoCached * (1 - pwReduction) * DteiDataCenterNormal * kbToMb * mbToGb * serverGridIntensity * (1 - cdnVal);//Page weight reduction smartphones = (1-11%), Dtei Data Center Normal = 0.05, conversion from KB to GB


            console.log("Server Emi Home Avg Cached Mob", ServerEmiHomeAvgCachedMob);
            console.log("Server Emi Home Avg NoCached Mob", ServerEmiHomeAvgNoCachedMob);
            console.log("alternative Server Emi Avg Cached Mob", alternativeServerEmiHomeAvgCachedMob);
            console.log("alternative Server Emi Avg NoCached Mob", alternativeServerEmiHomeAvgNoCachedMob);

            // pageperVisit = totalPageview/totalvisits
            let serverEmiAvgMob = ((ServerEmiHomeAvgNoCachedMob * newUsers + ServerEmiHomeAvgCachedMob * returningUsers) * (1 / (totalpageV / totalVisits))) + ((alternativeServerEmiHomeAvgNoCachedMob * newUsers + alternativeServerEmiHomeAvgCachedMob * returningUsers) * (1 - (1 / (totalpageV / totalVisits))));


            //below if condition will not be executed as 'Use all cache' option selector has been removed from front end and value has been hardcoded = 'y'
            // if (drop2Value === "n") {
            //   serverEmiAvgMob = ServerEmiHomeAvgNoCachedMob + alternativeServerEmiHomeAvgNoCachedMob; //no lazy loading value ; pageperVisit = totalPageview/totalvisits
            // }

            console.log("serverEmissionAvgMobile", serverEmiAvgMob);
            //let serverEmiAvgGlobal = (serverEmiAvg / serverGridIntensity) * 475;

            let serverEnergy = 0;
            serverEnergy = ((serverEmiAvgMob * pageSplit[itr].mobileVal) + (serverEmiAvg * pageSplit[itr].desktopVal)) / serverGridIntensity;

            console.log("server Energy perpageview", serverEnergy);

            let serverEnergyperVisit = 0;
            serverEnergyperVisit = serverEnergy * (totalpageV / totalVisits);

            console.log("server Energy per Visit", serverEnergyperVisit);

            let serverEnergyTotal = serverEnergyperVisit * (totalVisits) * 12;

            console.log("server Energy total", serverEnergyTotal);

            // let dataTransmissionGridIntensity = 254;
            // if(drop3Value=='CA')
            // {
            //   dataTransmissionGridIntensity = 120;
            // }

            let transmissionGridList = allTableData[3];

            let dataTransmissionGridIntensity = 709; //constant intialization, value set below
            let nWork = 'us to us';
            if (serveLocation == 'US' && drop3Value == 'US') {
              for (let i = 0; i < transmissionGridList.length; i++) {
                if (transmissionGridList[i].network == "Server  in US, end user in US") {
                  dataTransmissionGridIntensity = transmissionGridList[i].transmission_efs_grCO2_kWh;
                  nWork = 'us to us';
                  console.log("Transmission = ", transmissionGridList[i].network);
                }
              }
            }

            if ((serveLocation == 'US' && drop3Value == 'CA') || (serveLocation == 'CA' && drop3Value == 'US')) {
              for (let i = 0; i < transmissionGridList.length; i++) {
                if (transmissionGridList[i].network == "Server  in US, end user in Canada or vice-versa") {
                  dataTransmissionGridIntensity = transmissionGridList[i].transmission_efs_grCO2_kWh;
                  nWork = 'us to ca (or vice-versa)';
                  console.log("Transmission = ", transmissionGridList[i].network);
                }
              }
            }

            if (serveLocation == 'CA' && drop3Value == 'CA') {
              for (let i = 0; i < transmissionGridList.length; i++) {
                if (transmissionGridList[i].network == "Server  in Canada, end user in Canada") {
                  dataTransmissionGridIntensity = transmissionGridList[i].transmission_efs_grCO2_kWh;
                  nWork = 'ca to ca';
                  console.log("Transmission = ", transmissionGridList[i].network);
                }
              }
            }

            if (serveLocation == 'FR' && drop3Value == 'FR') {
              for (let i = 0; i < transmissionGridList.length; i++) {
                if (transmissionGridList[i].network == "Server in France, end user in France") {
                  dataTransmissionGridIntensity = transmissionGridList[i].transmission_efs_grCO2_kWh;
                  nWork = 'fr to fr';
                  console.log("Transmission = ", transmissionGridList[i].network);
                }
              }
            }

            if (serveLocation == 'US' && drop3Value == 'FR') {
              for (let i = 0; i < transmissionGridList.length; i++) {
                if (transmissionGridList[i].network == "Server in US, end user in France") {
                  dataTransmissionGridIntensity = transmissionGridList[i].transmission_efs_grCO2_kWh;
                  nWork = 'us to fr';
                  console.log("Transmission = ", transmissionGridList[i].network);
                }
              }
            }

            if (serveLocation == 'CA' && drop3Value == 'FR') {
              for (let i = 0; i < transmissionGridList.length; i++) {
                if (transmissionGridList[i].network == "Server  in Canada, end user in France") {
                  dataTransmissionGridIntensity = transmissionGridList[i].transmission_efs_grCO2_kWh;
                  nWork = 'ca to fr';
                  console.log("Transmission = ", transmissionGridList[i].network);
                }
              }
            }

            if (serveLocation == 'other') {
              for (let i = 0; i < transmissionGridList.length; i++) {
                if (transmissionGridList[i].network == "Other") {
                  dataTransmissionGridIntensity = transmissionGridList[i].transmission_efs_grCO2_kWh;
                  nWork = 'other';
                  console.log("Transmission = ", transmissionGridList[i].network);
                }
              }
            }

            let dTww = 867;
            for (let i = 0; i < transmissionGridList.length; i++) {
              if (transmissionGridList[i].network == "Other") {
                dTww = transmissionGridList[i].transmission_efs_grCO2_kWh;
                //nWork = 'other';
                console.log("Transmission = ", transmissionGridList[i].network);
              }
            }

            console.log("DATA TANSMISSION GRID WW", dTww)

            console.log("DATA TANSMISSION GRID LOCAL", dataTransmissionGridIntensity);

            dataTransmissionGridIntensity = dataTransmissionGridIntensity * (totalPagevisits / (totalPageVisitsww + totalPagevisits)) + dTww * (1 - (totalPagevisits / (totalPageVisitsww + totalPagevisits)));

            console.log("DATA TANSMISSION GRID TOTAL", dataTransmissionGridIntensity);


            let transmissionHomePageCached = homePageAvgCached * DteiNetworkWiFi * kbToMb * mbToGb * dataTransmissionGridIntensity; //Dtei Network Wi-Fi=0.03, conversion from KB to GB

            let transmissionHomePageNoCached = homePageAvgNoCached * DteiNetworkWiFi * kbToMb * mbToGb * dataTransmissionGridIntensity; //Dtei Network Wi-Fi=0.03, conversion from KB to GB

            let alternativetransmissionHomePageCached = alternativehomePageAvgCached * DteiNetworkWiFi * kbToMb * mbToGb * dataTransmissionGridIntensity; //Dtei Network Wi-Fi=0.03, conversion from KB to GB

            let alternativetransmissionHomePageNoCached = alternativehomePageAvgNoCached * DteiNetworkWiFi * kbToMb * mbToGb * dataTransmissionGridIntensity; //Dtei Network Wi-Fi=0.03, conversion from KB to GB


            console.log("transmission HomePage Cached", transmissionHomePageCached);
            console.log("transmission HomePage NoCached", transmissionHomePageNoCached);
            console.log("alternative transmission Page Cached", alternativetransmissionHomePageCached);
            console.log("alternative transmission Page NoCached", alternativetransmissionHomePageNoCached);


            // let TransmissionEmiAvg = ((transmissionHomePageNoCached * newUsers + transmissionHomePageCached * returningUsers) * (1 / pagePerVisits[itr])) + ((alternativetransmissionHomePageNoCached * newUsers + alternativetransmissionHomePageCached * returningUsers) * (1 - (1 / pagePerVisits[itr])));  //pageperVisit = totalPageview/totalvisits
            let TransmissionEmiAvg = ((transmissionHomePageNoCached * newUsers + transmissionHomePageCached * returningUsers) * (1 / (totalpageV / totalVisits))) + ((alternativetransmissionHomePageNoCached * newUsers + alternativetransmissionHomePageCached * returningUsers) * (1 - (1 / (totalpageV / totalVisits))));  //pageperVisit = totalPageview/totalvisits


            //below if condition will not be executed as 'Use all cache' option selector has been removed from front end and value has been hardcoded = 'y'
            // if (drop2Value === "n") {
            //   TransmissionEmiAvg = transmissionHomePageNoCached + alternativetransmissionHomePageNoCached; //no lazy loading value ; pageperVisit = totalPageview/totalvisits
            // }

            console.log("Data Transmission Dekstop Avg", TransmissionEmiAvg);


            let transmissionHomePageCachedMob = homePageAvgCached * (1 - pwReduction) * DteiNetworkSmartphonesAvg * kbToMb * mbToGb * dataTransmissionGridIntensity; //Page weight reduction smartphones = (1-11%), Dtei Network Smartphones global average=0.07, conversion from KB to GB

            let transmissionHomePageNoCachedMob = homePageAvgNoCached * (1 - pwReduction) * DteiNetworkSmartphonesAvg * kbToMb * mbToGb * dataTransmissionGridIntensity; //Page weight reduction smartphones = (1-11%), Dtei Network Smartphones global average=0.07, conversion from KB to GB

            let alternativetransmissionHomePageCachedMob = alternativehomePageAvgCached * (1 - pwReduction) * DteiNetworkSmartphonesAvg * kbToMb * mbToGb * dataTransmissionGridIntensity; //Page weight reduction smartphones = (1-11%), Dtei Network Smartphones global average=0.07, conversion from KB to GB

            let alternativetransmissionHomePageNoCachedMob = alternativehomePageAvgNoCached * (1 - pwReduction) * DteiNetworkSmartphonesAvg * kbToMb * mbToGb * dataTransmissionGridIntensity; //Page weight reduction smartphones = (1-11%), Dtei Network Smartphones global average=0.07, conversion from KB to GB


            console.log("transmission HomePage Cached Mob", transmissionHomePageCachedMob);
            console.log("transmission HomePage NoCached Mob", transmissionHomePageNoCachedMob);
            console.log("alternative transmission Page Cached Mob", alternativetransmissionHomePageCachedMob);
            console.log("alternative transmission NoCached Mob", alternativetransmissionHomePageNoCachedMob);


            //pageperVisit = totalPageview/totalvisits
            // let TransmissionEmiAvgMob = ((transmissionHomePageNoCachedMob * newUsers + transmissionHomePageCachedMob * returningUsers) * (1 / pagePerVisits[itr])) + ((alternativetransmissionHomePageNoCachedMob * newUsers + alternativetransmissionHomePageCachedMob * returningUsers) * (1 - (1 / pagePerVisits[itr])));

            let TransmissionEmiAvgMob = ((transmissionHomePageNoCachedMob * newUsers + transmissionHomePageCachedMob * returningUsers) * (1 / (totalpageV / totalVisits))) + ((alternativetransmissionHomePageNoCachedMob * newUsers + alternativetransmissionHomePageCachedMob * returningUsers) * (1 - (1 / (totalpageV / totalVisits))));

            // if (drop2Value === "n") {
            //   TransmissionEmiAvgMob = transmissionHomePageNoCachedMob + alternativetransmissionHomePageNoCachedMob; //no lazy loading value ; pageperVisit = totalPageview/totalvisits
            // }

            console.log("Data Transmission Mobil Avg", TransmissionEmiAvgMob);
            //let transmissionEmiAvgGlobal = (TransmissionEmiAvg / dataTransmissionGridIntensity) * 475;



            let gridTable = allTableData[2];


            let endDeviceGridIntensity = 709; //constant intialization, value set below

            let endGridww = 867;

            for (let i = 0; i < gridTable.length; i++) {
              if (gridTable[i].device_location == "End user outside Canada") {
                endGridww = gridTable[i].device_efs_grCO2_kWh;
                endDeviceGridIntensity = gridTable[i].device_efs_grCO2_kWh;
              }
            }
            if (drop3Value == 'CA') {
              for (let i = 0; i < gridTable.length; i++) {
                if (gridTable[i].device_location == "End user located in Canada ") {
                  endDeviceGridIntensity = gridTable[i].device_efs_grCO2_kWh;
                }
              }
            }
            if (drop3Value == 'FR') {
              for (let i = 0; i < gridTable.length; i++) {
                if (gridTable[i].device_location == "End user located in France") {
                  endDeviceGridIntensity = gridTable[i].device_efs_grCO2_kWh;
                }
              }
            }
            if (drop3Value == 'US') {
              for (let i = 0; i < gridTable.length; i++) {
                if (gridTable[i].device_location == "End user located in US") {
                  endDeviceGridIntensity = gridTable[i].device_efs_grCO2_kWh;
                }
              }
            }

            console.log("END GRID WW", endGridww)

            console.log("END GRID LOCAL", endDeviceGridIntensity);

            endDeviceGridIntensity = endDeviceGridIntensity * (totalPagevisits / (totalPageVisitsww + totalPagevisits)) + endGridww * (1 - (totalPagevisits / (totalPageVisitsww + totalPagevisits)));

            console.log("END GRID TOTAL", endDeviceGridIntensity);

            let endDevicePageNoCached = homePageAvgNoCached * DteiComputer * kbToMb * mbToGb * endDeviceGridIntensity; //Dtei computer=0.12, conversion from KB to GB

            let alternativeendDevicePageNoCached = alternativehomePageAvgNoCached * DteiComputer * kbToMb * mbToGb * endDeviceGridIntensity; //Dtei computer=0.12, conversion from KB to GB

            console.log("endDevice Page NoCached", endDevicePageNoCached);
            console.log("alternative endDevice Page NoCached", alternativeendDevicePageNoCached);


            //let endDeviceEmiAvg = (endDevicePageNoCached * (1 / pagePerVisits[itr])) + (alternativeendDevicePageNoCached * (1 - (1 / pagePerVisits[itr])));
            let endDeviceEmiAvg = (endDevicePageNoCached * (1 / (totalpageV / totalVisits))) + (alternativeendDevicePageNoCached * (1 - (1 / (totalpageV / totalVisits))));

            console.log("endDevice Emi Avg Desktop", endDeviceEmiAvg);

            console.log("dteimob", DteiMobile);

            let endDevicePageNoCachedMob = homePageAvgNoCached * (1 - pwReduction) * DteiMobile * kbToMb * mbToGb * endDeviceGridIntensity; //Dtei mobile=0.24, conversion from KB to GB

            let alternativeendDevicePageNoCachedMob = alternativehomePageAvgNoCached * (1 - pwReduction) * DteiMobile * kbToMb * mbToGb * endDeviceGridIntensity; //Dtei mobile=0.24, conversion from KB to GB


            console.log(" endDevice Page NoCached Mob", endDevicePageNoCachedMob);
            console.log("alternative endDevice Page NoCached Mob", alternativeendDevicePageNoCachedMob);


            //let endDeviceEmiAvgMob = (endDevicePageNoCachedMob * (1 / pagePerVisits[itr])) + (alternativeendDevicePageNoCachedMob * (1 - (1 / pagePerVisits[itr])));
            let endDeviceEmiAvgMob = (endDevicePageNoCachedMob * (1 / (totalpageV / totalVisits))) + (alternativeendDevicePageNoCachedMob * (1 - (1 / (totalpageV / totalVisits))));


            console.log("endDevice Emi Avg Mobile", endDeviceEmiAvgMob);

            //let endDeviceEmiAvgGlobal = (endDeviceEmiAvg / endDeviceGridIntensity) * 475;


            //let totalEmissionNoCached = ServerEmiHomeAvgNoCached + transmissionHomePageNoCached + endDevicePageNoCached;

            //let totalEmissionCached = ServerEmiHomeAvgCached + transmissionHomePageCached + endDevicePageNoCached;

            let totalEmissionIntensityPerpageViewLocal = 0;
            totalEmissionIntensityPerpageViewLocal = ((serverEmiAvg + TransmissionEmiAvg + endDeviceEmiAvg) * pageSplit[itr].desktopVal) + ((serverEmiAvgMob + TransmissionEmiAvgMob + endDeviceEmiAvgMob) * pageSplit[itr].mobileVal);

            let vartotalEmissionIntensityLocal = 0;
            vartotalEmissionIntensityLocal = totalEmissionIntensityPerpageViewLocal * (totalpageV / totalVisits);

            let vartotalEmissionLocalperyear = vartotalEmissionIntensityLocal * (totalVisits) * 12 * 0.001;

            let vartotalEmissionLocalLifecycleperyear = vartotalEmissionLocalperyear * 3.532 * 0.001;

            let emissionAvg = 0;
            let cntEm1 = 0, emiCheckArr = [];
            for (let i = pageEmissionTable.length - 1; i >= 0; i--) {
              if (pageEmissionTable[i].emission_perPageview && !emiCheckArr.includes(pageEmissionTable[i].domain)) {
                emissionAvg = emissionAvg + pageEmissionTable[i].emission_perPageview;
                cntEm1++;
                emiCheckArr.push(pageEmissionTable[i].domain);
              }
            }
            console.log('domain list for avg checking', emiCheckArr);

            emissionAvg = emissionAvg / cntEm1;

            // emissionMillionAvg = emissionMillionAvg/cntEm2;

            let emiDiff = ((totalEmissionIntensityPerpageViewLocal - emissionAvg) / emissionAvg) * 100;

            if (emiDiff < 0) {
              setEmissionDiffString("lower");
            }
            else {
              setEmissionDiffString("higher");
            }

            setEmissionDiff(Math.abs(emiDiff.toFixed(2)));


            //setTotalEmissionIntensityLocal(vartotalEmissionIntensityLocal.toFixed(3) + " gCO2e");
            setTotalEmissionLocalperyear(vartotalEmissionLocalperyear.toFixed(3) + " kgCO2e");
            setTotalEmissionLocalLifecycleperyear(vartotalEmissionLocalLifecycleperyear.toFixed(3) + " TCO2e");

            console.log("emissions per year", vartotalEmissionLocalperyear);


            let totalEmi = vartotalEmissionIntensityLocal / (totalpageV / totalVisits);



            //setTotalEmissionIntensityLocalPageview(totalEmi.toFixed(3) + " gCO2e");


            //let vartotalEmissionIntensityPerpageViewGlobal = serverEmiAvgGlobal + transmissionEmiAvgGlobal + endDeviceEmiAvgGlobal;

            //setTotalEmissionIntensityPerpageViewGlobal(vartotalEmissionIntensityPerpageViewGlobal.toFixed(3) + " gr CO2e");

            let emiperMVisit = 0;
            emiperMVisit = vartotalEmissionIntensityLocal * 1000;
            //setEmissionsperMvisit(emiperMVisit.toFixed(0) + " kgCO2e")


            //setEmission(serverEmiAvgGlobal.toFixed(3));


            // setEmissionsperMDiff = ((emiperMVisit - emissionMillionAvg)/emissionMillionAvg) * 100;

            // setEmissionsperMDiff = ((emiperMVisit - emissionMillionAvg)/emissionMillionAvg) * 100;

            let cDate = new Date();
            //let dt = cDate.getDate() + "/" + (cDate.getMonth() + 1) + "/" + cDate.getFullYear() + " " + cDate.getHours() + ":" + cDate.getMinutes() + ":" + cDate.getSeconds();;

            let dt = cDate.getFullYear() + "-" + (cDate.getMonth() + 1) + "-" + cDate.getDate() + " " + cDate.getHours() + ":" + cDate.getMinutes() + ":" + cDate.getSeconds();
            console.log("currDate", dt);
            let dt_split = dt.split(" ");

            //setEqiTree((emiperMVisit / 25).toFixed(2));
            //setEqiFlight((emiperMVisit / 1000).toFixed(2));



            if (itr == (mainPageURL.length - 1)) {
              totalEmiArr = totalEmiArr + totalEmissionIntensityPerpageViewLocal.toFixed(3);
              totalEmiVisitArr = totalEmiVisitArr + vartotalEmissionIntensityLocal.toFixed(3);
              emiperMVisitArr = emiperMVisitArr + emiperMVisit.toFixed(0);
              eqiTreeArr = eqiTreeArr + (emiperMVisit / 25).toFixed(2);
              eqiFlightArr = eqiFlightArr + (emiperMVisit / 1000).toFixed(2);
            }
            else {
              totalEmiArr = totalEmiArr + totalEmissionIntensityPerpageViewLocal.toFixed(3) + ",";
              totalEmiVisitArr = totalEmiVisitArr + vartotalEmissionIntensityLocal.toFixed(3) + ",";
              emiperMVisitArr = emiperMVisitArr + emiperMVisit.toFixed(0) + ",";
              eqiTreeArr = eqiTreeArr + (emiperMVisit / 25).toFixed(2) + ",";
              eqiFlightArr = eqiFlightArr + (emiperMVisit / 1000).toFixed(2) + ",";
            }

            console.log("emissions per year - flight", emiperMVisit / 1000);

            console.log("emissions per year - trees", emiperMVisit / 25);

            let sLocation = 'us total';

            if (serveLocation == 'US') {
              sLocation = 'us total';
            }
            if (serveLocation == 'CA') {
              sLocation = 'canada average';
            }
            if (serveLocation == 'other') {
              sLocation = 'average ww';
            }

            if (serveLocation == 'FR') {
              sLocation = 'france average';
            }



            let pgURL = mainPageURL[itr];

            if (mainPageURL[itr].includes("https://www.") || mainPageURL[itr].includes("https://" || mainPageURL[itr].includes("www.")) || mainPageURL[itr].includes("http://")) {
              pgURL = mainPageURL[itr];
            }
            else if (mainPageURL[itr].startsWith("www.")) {
              pgURL = "https://" + mainPageURL[itr];
            }
            else {
              pgURL = "https://www." + mainPageURL[itr];
            }

            if (pgURL.slice(-1) != "/" && (pgURL.charAt(pgURL.length - 3) == '.' || pgURL.charAt(pgURL.length - 4) == '.'))
              pgURL = pgURL + "/";

            console.log(pgURL);

            await axios.post('https://footsprint.uc.r.appspot.com/pushEmissionData?key=' + loc.state.k.k4,
              {
                domain: domainName[itr],
                page_url: pgURL,
                page_weight: pageWeight[itr],
                cdn: cdnSel,
                server_location: sLocation,
                network: nWork,
                user_location: drop3Value.toLowerCase(),
                domain_visits: pageVisits[itr],
                pages_per_domain_visits: pagePerVisits[itr],
                date: dt_split[0],
                time: dt_split[1],
                energy_page: serverEnergy.toFixed(3),
                energy_visit: serverEnergyperVisit.toFixed(3),
                energy_total: serverEnergyTotal.toFixed(3),
                emission_perPageview: totalEmissionIntensityPerpageViewLocal.toFixed(3),
                page_emission_visit: vartotalEmissionIntensityLocal.toFixed(3),
                emission_perM: emiperMVisit.toFixed(3),
                emission_equivalence_flight: (emiperMVisit / 1000).toFixed(3),
                emission_equivalence_trees: (emiperMVisit / 25).toFixed(3),
                version: "one"

              })
              .then(response => {
                console.log(response.data);
              })
              .catch(error => {
                console.log(error);
              });
          }
          else {
            errValArr = errValArr + mainPageURL[itr] + ",";
          }
        }
        setTotalEmissionIntensityLocalPageview(totalEmiArr);
        setTotalEmissionIntensityLocal(totalEmiVisitArr);
        setEmissionsperMvisit(emiperMVisitArr);
        setEqiTree(eqiTreeArr);
        setEqiFlight(eqiFlightArr);
        setErrorVal(errValArr);
        console.log("Error URls: ", errValArr);
      }
      setPageWeight([]);
      setPageVisits([]);
      setPagePerVisits([]);

      setTotalEmissionLocalperyear("");
      setTotalEmissionLocalLifecycleperyear("");
      setTotalEmissionIntensityPerpageViewGlobal("");
      setEmission("");
      setDomainName([]);

      setwwVisits([]);

      setErrorMsg(0);
      setEmissionDiff(0);
      setEmissionDiffString("");

      setMainPageURL([]);


      setCdn([]);
    }
    cal();
  }, [calculate]);

  function numWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function handleKey(e) {
    e.preventDefault();
    if (e.keyCode == 13) {
      console.log("enter pressed");
      handleSubmit(e);
    }
  }

  function navigateLogin() {
    let k = navLogin;
    k++;
    setNavLogin(k);
  }
  let nav = useNavigate();
  useEffect(() => {
    if (navLogin >= 1)
      nav("/");
  }, [navLogin])



  return (
    <div className="Calculator">
      {
        (authVal) ?
          <div>
            <h1>Website Page Carbon Calculator</h1><br /><br />
            {spinLoading && <Spinner>Loading...</Spinner>}
            <form >

              Enter URLs <br /> <textarea required id="first" rows={5} cols={100} value={mainPage} onChange={(e) => setMainPage(e.target.value)} /> <br /><br />
              <label>
                Lazy Loading
                <select value={drop1Value} onKeyPress={handleKey} onChange={handleDrop1}>
                  <option value="y" selected="selected">Yes</option>
                  <option value="n">No</option>
                </select>
              </label> <br /><br />

              <label>
                Use Cache
                <select value={drop2Value} onKeyPress={handleKey} onChange={handleDrop2}>
                  <option value="y">Yes</option>
                  <option value="n">No</option>
                </select>
              </label> <br /><br />

              <label>
                End user location &nbsp;
                <select value={drop3Value} onKeyPress={handleKey} onChange={handleDrop3}>
                  <option value="US">US</option>
                  <option value="CA">CA</option>
                  <option value="FR">FR</option>
                  <option value="Other">Other</option>
                </select>
              </label> <br /><br />
            </form>
            {(errorMsg) ? <div> <button onClick={handleSubmit}>Calculate</button><p>{errorMsg}</p></div> :
              <div>
                {/* <p>Domain visits = {numWithCommas(Math.round(pageVisits))}</p>
            <p>Pagesviews per visit = {pagePerVisits}</p>
            <p>Page weight = {numWithCommas(Math.round(pageWeight)) + " KB"}</p>
            <p>CDN = {cdn}</p> */}
                <button onClick={handleSubmit}>Calculate</button>
                <p> Emissions per pageview = {totalEmissionIntensityLocalPageview} </p>
                <p> Emissions per visit = {totalEmissionIntensityLocal} </p>

                {/* <p> Total Emissions per year Local = {totalEmissionLocalperyear} </p>
            <p> Total Local Lifecycle Emissions = {totalEmissionLocalLifecycleperyear} </p> */}
                {/* Total Emissions Intensity per pageview Global */}
                {/* <p> Emissions per pageview (Global) = {totalEmissionIntensityPerpageViewGlobal} </p> */}
                {/* </div> : <div> */}
                <p> Emissions per 1M visits  = {emissionsperMvisit} </p> <br />
                <p> Equivalent Flight Emissions per Million Visits = {eqiFlight} </p>
                <p> Equivalent Tree Emissions per Million Visits = {eqiTree} </p> <br />
                <p> Error URLs = {errorVal} </p>
                {/* {(emissionDiff) ? <p> Emissions per pageview is {emissionDiff}% {emissionDiffString} than median of the websites we audit </p> : <div></div>} */}
              </div>
            }
          </div> : <div>

            {navLogin < 1 ? navigateLogin() : <button onClick={navigateLogin}>Go Back to Login Page</button>}

            {/* <button ref={this.button} onClick={navigateLogin}>Go Back to Login Page</button> */}

          </div>}
    </div>
  );

}

export default Calculator;
