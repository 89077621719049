import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Login from './Login/Login'
import Calculator from './carbon-calculator-page-bulk/Calculator'

function App() {

  return (
    <Router>
      <Routes>
        <Route path='/carbon-calculator-page-bulk' element={<Calculator />}></Route>
        <Route path='/' element={<Login />}></Route>
      </Routes>
    </Router>
  );
}

export default App;